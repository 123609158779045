import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { errorAlert, successAlert, successAlert_sp } from '../../../../utility/alert'
import { data } from 'jquery'
import Swal from 'sweetalert2'
import axios from 'axios'

export const getUsers = createAsyncThunk('Users/list', async params => {
  const response = await axios.get(
    `https://api.joycity.club/api/v1/web/users?with_paginate=yes&sort_by=id&sort=asc&search=${
      params.searchname ? params.searchname : ''
    }`,
    { params }
  )
  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.total,
    loading: true
  }
})

export const editUser = createAsyncThunk('Users/list/edit', async (params, { dispatch, getState }) => {
  await axios
    .post(`https://api.joycity.club/api/v1/web/users/${params.id}`, params.formData)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })
  await dispatch(getUsers(getState().users.params))
})
export const deleteParent = createAsyncThunk('Users/list/delete', async (id, { dispatch, getState }) => {
  await axios
    .delete(`https://api.joycity.club/api/v1/web/users/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err?.response?.data?.messages[0])
    })
  await dispatch(getUsers(getState().users.params))
})
export const ChangePassword = createAsyncThunk('usesrs/password', async (paramas, { dispatch, getState }) => {
  await axios
    .post('https://api.joycity.club/api/v1/web/users/change-password', paramas.formData)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })
})

export const UsersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 1,
    params: {},
    loading: false,
    status: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUsers.pending, (state, action) => {})
      .addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload.data
        /*         state.params = action.payload.params
         */ state.total = action.payload.totalPages
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.status = true
      })
  }
})

export default UsersSlice.reducer
