// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appAdmins/getAllData', async () => {
  const response = await axios.get('/api/reservations/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appAdmins/getData', async params => {
  const response = await axios.get('https://api.joycity.club/api/v1/web/admins?with_paginate=yes&sort_by=id&sort=asc', {
    params
  })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.total,
    loading: true
  }
})
export const getParents = createAsyncThunk('appAdmins/getParents', async params => {
  const response = await axios.get(
    'https://joycitybackend.bw-businessworld.com/api/admin/Users?with_paginate=no',
    params
  )
  return {
    parents: response.data
  }
})
export const getKids = createAsyncThunk('appAdmins/getKids', async params => {
  const response = await axios.get('https://joycitybackend.bw-businessworld.com/api/users?with_paginate=no', params)
  return {
    kids: response.data
  }
})
export const getUser = createAsyncThunk('appAdmins/getUser', async id => {
  const response = await axios.get('/api/reservations/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appAdmins/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/reservations/add-user', user)
  await dispatch(getData(getState().reservations.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appAdmins/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/reservations/delete', { id })
  await dispatch(getData(getState().reservations.params))
  await dispatch(getAllData())
  return id
})

export const appAdminsSlice = createSlice({
  name: 'appAdmins',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    parents: [],
    kids: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(getParents.fulfilled, (state, action) => {
        state.parents = action.payload.parents
      })
      .addCase(getKids.fulfilled, (state, action) => {
        state.kids = action.payload.kids
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appAdminsSlice.reducer
