// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
/* let token = JSON.parse(localStorage.getItem('user'))?.user?.access_token
  ? JSON.parse(localStorage.getItem('user')).user?.access_token
  : ''
 */
// ** Axios Imports
import axios from 'axios'
export const getStatistics = createAsyncThunk('/appreservations/stastistics', async () => {
  try {
    const response = await axios.get('https://api.joycity.club/api/v1/web/statistic')
    return response.data
  } catch (error) {
    console.log(error)
  }
})
import { errorAlert, successAlert, successAlert_sp } from '../../../../utility/alert'
import { data } from 'jquery'
import Swal from 'sweetalert2'

export const getAllData = createAsyncThunk('reservations/getAllData', async () => {
  const response = await axios.get('/api/reservations/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('reservations/getData', async params => {
  const response = await axios.get(
    `https://api.joycity.club/api/v1/web/reservation/list?with_paginate=yes&search=${
      params.searchByResName ? params.searchByResName : ''
    }`,
    { params }
  )

  return {
    params,
    data: response.data.data,
    totalPages: response.data.data.total,
    loading: true
  }
})

export const getKids = createAsyncThunk('reservations/getKids', async params => {
  const response = await axios.get('https://api.joycity.club/api/v1/web/kids/list?with_paginate=no', params)
  return {
    kids: response.data.data
  }
})
export const getUser = createAsyncThunk('reservations/getUser', async id => {
  const response = await axios.get('/api/reservations/user', { id })
  return response.data.user
})
export const addReservation = createAsyncThunk('reservations/addReservation', async (user, { dispatch, getState }) => {
  await axios
    .post('https://api.joycity.club/api/v1/web/reservation/store', user)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })

  await dispatch(getData(getState().reservations.params))
  return user
})

export const deleteUser = createAsyncThunk('reservations/deleteUser', async (id, { dispatch, getState }) => {
  await axios
    .delete(`https://api.joycity.club/api/v1/web/reservation/delete/${id}`)
    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })
  await dispatch(getData(getState().reservations.params))
  return id
})

export const updateUser = createAsyncThunk('reservations/updateUser', async (params, { dispatch, getState }) => {
  await axios
    .post(`https://api.joycity.club/api/v1/web/reservation/update/${params.id}`, params.user)

    .then(res => {
      successAlert()
    })
    .catch(err => {
      errorAlert(err.response.data.messages[0])
    })
  await dispatch(getData(getState().reservations.params))
  return params.id
})
export const startReservation = createAsyncThunk(
  'reservations/startReservation',
  async (id, { dispatch, getState }) => {
    await axios
      .post(`https://api.joycity.club/api/v1/web/reservation/open/${id}`, { _method: 'put' })
      .then(res => {
        successAlert()
      })
      .catch(err => {
        errorAlert(err.response.data.messages[0])
      })
    await dispatch(getData(getState().reservations.params))
    return id
  }
)

export const endReservation = createAsyncThunk(
  'reservations/endReservation',
  async (params, { dispatch, getState }) => {
    const response = await axios
      .post(`https://api.joycity.club/api/v1/web/reservation/close/${params.id}`, params.dataa)
      .then(res => {
        successAlert()
        return res.data
      })
      .catch(err => {
        errorAlert(err.response.data.messages[0])
      })
    await dispatch(getData(getState().reservations.params))
    return response
  }
)
export const CurrentCost = createAsyncThunk('reservations/Current', async (id, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`https://api.joycity.club/api/v1/web/reservation/amount/${id}`, {
      _method: 'put'
    })

    successAlert_sp(' ' + data?.data?.cost + ' ' + 'sp' + ' ,   ' + 'Start_Date' + ' ' + data?.data?.entry_date)
  } catch (error) {
    errorAlert(error.data.messages[0])
  }
})

export const getResByName = createAsyncThunk('reservation/getKidsByName', async (params, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(
      `https://api.joycity.club/api/v1/web/reservation/list?sort_by=id&search=${
        params.searchByResName ? params.searchByResName : ''
      }`,
      {
        headers: {
          'X-localization': params.lang
        }
      }
    )
    return data.data
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    update_status: '',
    parents: [],
    endResulte: '',
    stastics: {},
    currentCost: '',
    ResList: '',
    end: false,
    loading: false,
    lodaingstat: 'idle',
    kids: [],
    selectedUser: null,
    last: false
  },
  reducers: {
    endState: (state, action) => {
      state.end = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(CurrentCost.fulfilled, (state, action) => {
        state.currentCost = action.payload
      })
      .addCase(CurrentCost.rejected, (state, action) => {
        state.currentCost = action.payload
        console.log(action.payload)
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = action.payload.loading
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.update_status = true
      })

      .addCase(getKids.fulfilled, (state, action) => {
        state.kids = action.payload.kids
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getStatistics.fulfilled, (state, action) => {
        state.stastics = action.payload
      })
      .addCase(getStatistics.pending, (state, action) => {
        state.lodaingstat = 'work'
      })
      .addCase(getResByName.fulfilled, (state, action) => {
        state.ResList = action.payload
      })
      .addCase(endReservation.fulfilled, (state, action) => {
        state.endResulte = action.payload
        state.end = true
      })
  }
})
export const { endState } = reservationsSlice.actions
export default reservationsSlice.reducer
